

































import Vue from 'vue';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { Component } from 'vue-property-decorator';

import BaseCenteredCard
  from '@improve/common-components/src/components/widgets/BaseCenteredCard.vue';
import EmailForm
  from '@improve/common-components/src/components/forms/EmailForm.vue';
import SSO_MODE from '@improve/common-utils/src/model/SsoMode';

@Component({
  name: 'LoginSSO',
  components: {
    BaseCenteredCard,
    EmailForm
  }
})
export default class LoginSSO extends Vue {
  @Action fetchOrgSsoMode!: ActionMethod;

  @Action fetchOrgSsoLogin!: ActionMethod;

  email: string | null = null;

  errorHappened = false;

  validateAndSubmit(): Promise<boolean> {
    if (this.$refs.obs) {
      const comp: any = this.$refs.obs;
      return comp.validate();
    }
    this.errorHappened = false;
    return Promise.resolve(false);
  }

  async loginSubmit(event: { email: string }): Promise<void> {
    if (!(await this.validateAndSubmit())) {
      return;
    }

    let ssoMode;
    try {
      ssoMode = await this.fetchOrgSsoMode(event.email);
    } catch (e) {
      // Devour error handled below:
    }

    if (!ssoMode || ssoMode === SSO_MODE.NOT_SET) {
      this.$router.push({
        name: 'Register',
        params: {
          demo: 'true'
        }
      });
      return;
    }

    window.location.href = await this.fetchOrgSsoLogin(event.email);
  }
}
